<template lang="pug">
  .jwl-undo-publish(v-if="loaded")
    template(v-if="allRequestsHandled")
      h3.jwl-undo-publish__headline
        | Request to change grade
      .
        This Task has been graded.
        You can request to change this grade if you have done a mistake or a good reason to change it.
        Your request will be reviewed by JWL and either be approved or declined.

      .jwl-undo-publish__form
        label.jwl-undo-publish__hidden-label(for="explanation")
          | Please provide an explanation:
        textarea#explanation(
          v-model="explanation"
          placeholder="Please provide an explanation, why you need to change this Grade")
        .jwl-undo-publish__form-error.jwl-undo-publish__submit-error(v-if="formError")
          | Please provide an explanation (min. 10 chars)

        .jwl-undo-publish__form-row
          label(for="include-student")
            | Also allow the student to modify their work.
          input#include-student(type="checkbox" v-model="includeStudent")

        jwl-button.jwl-undo-publish__submit(v-if="submitStatus !== 1" @button-clicked="submitRequest")
          | {{$t('gradebook.requestUndoPublish')}}

        .jwl-undo-publish__submitting(v-if="submitStatus === 1")
          | {{$t('syncStatus.submitting')}}

        .jwl-undo-publish__submitted(v-else-if="submitStatus === 2")
          | {{$t('syncStatus.submitted')}}

        .jwl-undo-publish__submit-error(v-else-if="submitStatus === -1")
          | {{error.message}}

    .jwl-undo-publish__history(v-if="requestState && requestState.length > 0")
      h3.jwl-undo-publish__headline
        | Previous Change Requests

      ol.jwl-undo-publish__history-list
        li.jwl-undo-publish__history-item(v-for="requested in sortedRequests")
          component.jwl-undo-publish__history-icon(:is="requestIcon(requested.status)")
          .jwl-undo-publish__history-explanation(v-html="requested.explanation")
          .jwl-undo-publish__history-meta
            | Requested by {{requested.requestedBy.name}} on {{formattedDate(requested.requestedAt)}}

</template>

<script>
import JwlButton from '@/components/JwlButton.vue';

const IconCheck = () => import('common-modules/src/assets/fa-icons/regular/check.svg');
const IconClock = () => import('common-modules/src/assets/fa-icons/regular/clock.svg');
const IconTimes = () => import('common-modules/src/assets/fa-icons/regular/times.svg');

const MIN_CHARS = 10;

export default {
  name: 'jwl-undo-publish',
  components: {
    IconCheck,
    IconClock,
    IconTimes,
    JwlButton,
  },
  props: {
    submitId: Number,
  },
  data () {
    return {
      loaded: false,
      submitStatus: 0,
      error: null,
      showForm: false,
      explanation: '',
      includeStudent: false,
      formError: false,
      requestState: [],
    };
  },
  methods: {
    submitRequest () {
      if (this.submitStatus === 1) {
        return;
      }

      this.formError = this.explanation.length <= MIN_CHARS;

      if (!this.formError) {
        this.submitStatus = 1;
        const FD = new FormData();
        FD.append('submitId', this.submitId.toString());
        FD.append('explanation', this.explanation);
        FD.append('includeStudent', this.includeStudent.toString());

        this.$store.dispatch('postData', {
          url: 'undo-publish/request',
          formData: FD,
        }).then((data) => {
          this.submitStatus = 2;
          this.requestState = data;
        }).catch((e) => {
          this.error = e;
          this.submitStatus = -1;
        });
      }
    },
    requestIcon (requestState) {
      switch (requestState) {
        case 'accepted':
          return 'icon-check';
        case 'declined':
          return 'icon-times';
        default:
          return 'icon-clock';
      }
    },
    formattedDate (dateString) {
      const date = new Date(dateString);
      return date.toLocaleString();
    },
  },
  computed: {
    allRequestsHandled () {
      if (!this.requestState || this.requestState.length === 0) {
        return true;
      }
      return !this.requestState.find((requestStatus) => requestStatus.status === 'pending');
    },
    sortedRequests () {
      const sorted = this.requestState;
      sorted.sort((a, b) => b.id - a.id);
      return sorted;
    },
  },
  watch: {
    explanation (newVal) {
      if (this.formError && newVal.length > MIN_CHARS) {
        this.formError = false;
      }
    },
  },
  mounted () {
    this.$store.dispatch('getData', `undo-publish/requests/${this.submitId}`)
      .then((data) => {
        this.requestState = data;
        this.loaded = true;
      });
  },
};
</script>

<style lang="scss">
@use "~common-modules/src/styles/mixins";
@use "~common-modules/src/styles/variables";

.jwl-undo-publish {
  display: flex;
  flex-flow: row wrap;
  padding: 1rem 1.5rem;

  &__headline {
    width: 100%;
  }

  &__form {
    padding-top: 1.5rem;
    width: 100%;
  }

  &__hidden-label {
    display: none;
  }

  textarea {
    border: .08rem solid var(--color-grey);
    border-radius: var(--border-radius);
    box-sizing: border-box;
    font-family: variables.$font-family-sans-serif;
    font-size: 1rem;
    margin-bottom: 1rem;
    max-width: 100%;
    min-height: 4.5rem;
    min-width: 100%;
    padding: .5rem .75rem;
  }

  &__form-row {
    display: grid;
    grid-template: "checkbox label" auto / 2rem 1fr;
    margin-bottom: 1rem;

    label {
      grid-area: label;
    }

    input {
      grid-area: checkbox;
    }
  }

  &__submitting {
    color: var(--color-primary);
    padding: .5rem 1.25rem;
  }

  &__submitted {
    background-color: var(--color-success);
    border-radius: var(--border-radius);
    color: var(--color-white);
    margin-top: .5rem;
    padding: .25rem 1rem;
  }

  &__submit-error {
    background-color: var(--color-error);
    border-radius: var(--border-radius);
    color: var(--color-white);
    margin-top: .5rem;
    padding: .25rem 1rem;
  }

  &__form-error {
    margin-bottom: 1.5rem;
    margin-top: -.75rem;
  }

  &__history {
    width: 100%;
  }

  &__history-list {
    list-style: none;
    margin: 0 -1.5rem 1.5rem;
    padding: 0 0;
    width: calc(100% + 3rem);
  }

  &__history-item {
    background-color: var(--color-white);
    border-radius: var(--border-radius);
    box-shadow: 0 0 .25rem var(--color-gray-500);
    display: grid;
    grid-template: "icon explanation" auto "icon meta" auto / 3rem 1fr;
    margin-bottom: .75rem;
    padding: .5rem 1.5rem;
  }

  &__history-icon {
    font-size: 2rem;
    grid-area: icon;
    padding-top: .25rem;
  }

  &__history-explanation {
    grid-area: explanation;
    margin-bottom: .25rem;
  }

  &__history-meta {
    color: var(--color-grey);
    font-size: .85rem;
    grid-area: meta;
  }
}
</style>
